



















import UploadImg from "@/popupcomponents/uploadImg/UploadImg";
export default UploadImg;
