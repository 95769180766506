import { Vue, Component } from 'vue-property-decorator';
import Dropzone from '@/ui-components/dropzone/DropzoneComponent.vue';

@Component({
    components: {
        'dropzone': Dropzone
    }
})
export default class UploadImg extends Vue {
    $refs!: {
        dropzone: any
    }

    close() {
       this.$refs.dropzone.removeAllFiles();
    }

}